import React, { useEffect, useState } from "react"
import { Button, Card, Checkbox, Col, Divider, Form, Input, Image, message, Row, Steps } from "antd";
import axios from "axios"
import { useHistory } from "react-router";
import urlencode from "urlencode";
export const Login: React.FunctionComponent = () => {
  const history = useHistory();

  const [qqID, setQQId] = useState("")
  const [ttl, setTTL] = useState(0);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setTimeout(() => setRefresh(x => !x), 1000)
  }, [refresh])
  const getCode = (values: any) => {
    setQQId(values.qqID);
    console.log(values);
    axios.get(`/api/user/login/${values.qqID}`).then(data => {
      const result = data.data as {
        info: "success" | "error";
        data: any
      }
      if (result.info === "success") {
        setTTL(Date.now() + 60000);
      } else {
        if (result.data === "not a QQ Num Provided") {
          message.error("请提供正确QQ号码");
        }
        else if (result.data === "not a QQ Num in Main Group") {
          message.error("请确认QQ号码在主群中");
        } else {
          try {
            setTTL(Date.now() + parseInt(result.data));
          } catch (e) {
            message.error("未知错误，请联系圣光 1817408881");
            console.log(result.data);
            console.log(e);
          }
        }
      }
    })
  }
  const submit = (values: any) => {

    const vCode = values.verifyCode;
    if (qqID && vCode) {
      axios.get(`/api/user/login/${qqID}/${vCode}`).then(data => {
        const result = data.data as {
          info: "success" | "error";
          data: any
        };
        if (result.info === "success") {
          message.success("登录成功,正在跳转");
          axios.get(`/api/user/verify?redirectURI=${urlencode("/group")}`).then(data => {
            const result = data.data as {
              info: "success" | "error";
              data: any
            };
            if (result.info === "success") {
              window.location.href = result.data;
            } else {
              if (result.data === "verified") {
                history.push("/group");
              }
            }

          });

        } else {
          message.error("登录失败")
        }
      })
    }

  }
  return (
    <Row justify="center" align="middle" style={{ width: "100vw", height: "100vh" }}>
      <Col xs={22} sm={19} md={16} lg={13} xl={10} >
        <Card
          title="JSD登录"
        >
          <Form
            onFinish={getCode}
          >
            <Form.Item
              label="QQ号码"
              name="qqID"
              rules={[{ required: true, message: '请输入您的QQ号码' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item >
              <Button type="primary" htmlType="submit">
                {ttl <= Date.now() ? "请求验证码" : `请求成功（${Math.floor((ttl - Date.now()) / 1000)}s）`}
              </Button>
            </Form.Item>
          </Form>

          <Form
            onFinish={submit}
          >
            <Form.Item
              label="验证码"
              name="verifyCode"
              rules={[{ required: true, message: '请输入您的验证码' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item >
              <Button type="primary" htmlType="submit" disabled={!qqID}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )

}


export default Login;