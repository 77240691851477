import { Button, Image } from "antd";
import React from "react"
import ZX1 from "../../assets/zx1.jpg";
import ZX2 from "../../assets/zx2.jpg";
import { useTranslation } from 'react-i18next'

export const Terms: React.FunctionComponent<{ callback: () => void }> = ({ callback }) => {
  let { t } = useTranslation()

  return (
    <>
      <Image src={ZX1} style={{ maxWidth: "600px" }} /><Image src={ZX2} style={{ maxWidth: "600px" }} /><Button type="primary" block onClick={callback}>{t("DoneReading")}</Button>
    </>
  )
}
export default Terms;

