import { Button, Typography, Image } from "antd";
import React from "react"
import { useTranslation } from 'react-i18next'
import zh1 from "../../assets/zh-1.png"
import en1 from "../../assets/en-1.png"
import zh21 from "../../assets/zh21.png"
import en21 from "../../assets/en21.png"
import en22 from "../../assets/en22.png"
import en23 from "../../assets/en23.png"
import zh3 from "../../assets/zh-3.png"
import en3 from "../../assets/en-3.png"

const images: Record<string, Record<string, string>> = {
  step1: {
    zh: zh1,
    en: en1
  },
  step21: {
    zh: zh21,
    en: en21
  },
  step22: {
    zh: en22,
    en: en22
  },
  step23: {
    zh: en23,
    en: en23
  },
  step3: {
    zh: zh3,
    en: en3
  }
}

const { Title, Paragraph } = Typography;
export const Instruction: React.FunctionComponent<{ callback: () => void }> = ({ callback }) => {
  let { t, i18n } = useTranslation()
  return (
    <>
      <Title level={3}>{t("Instruction.step1.title")}</Title>
      <Paragraph>
        {t("Instruction.step1.text")}
      </Paragraph>
      <Image src={images.step1[t('lang')]} />
      <Title level={3}>{t("Instruction.step2.title")}</Title>
      <Paragraph>
        {t("Instruction.step2.text1")}
      </Paragraph>
      <Image src={images.step21[t('lang')]} />
      <Paragraph>
        {t("Instruction.step2.text2")}
      </Paragraph>
      <Image src={images.step22[t('lang')]} />

      <Paragraph>
        {t("Instruction.step2.text3")}
      </Paragraph>

      <Image src={images.step23[t('lang')]} />

      <Title level={3}>{t("Instruction.step3.title")}</Title>
      <Paragraph>
        {t("Instruction.step3.text1")}
      </Paragraph>
      <Image src={images.step3[t('lang')]} />
      <Title level={3}>{t("Instruction.step4.title")}</Title>
      <Paragraph>
        {t("Instruction.step4.text1")}
      </Paragraph>
      <Button type="primary" block onClick={callback}>{t("DoneReading")}</Button>
    </>
  )
}
export default Instruction;

