import React, { Suspense, lazy } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import HomePage from "./home";
import Login from "./user/login";
import DicePage from "./dice/dice";
import Tokuten from "./tokuten";
import Reg from "./reg";
import "../i18n"
// import Cafe from "./cafe";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const App: React.FunctionComponent = () => {
  const query = useQuery();
  const history = useHistory();
  return (
    <Switch>
      <Route path="/login"  >
        <Login />
      </Route>
      <Route path="/dice"  >
        <DicePage id={query.get("id") || ""}/>
      </Route> 
      <Route path="/reg"  >
        <Reg token={query.get("token") || ""} />
      </Route>
      <Route path="/tokuten/2024"  >
        <Tokuten />
      </Route>
      <Route path="*" >
        <HomePage />
      </Route>

    </Switch >
  );
};
export default App;    