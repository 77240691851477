/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import homeBackground from "../../assets/2024Gala/back.png";
import { TranslationOutlined } from "@ant-design/icons"
import { useTranslation, Trans, Translation } from 'react-i18next'
import cssStyle from "./index.module.css";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Image, message, Row, Steps, Select } from "antd";
import axios from "axios"
import jwt, { JwtPayload } from "jsonwebtoken"
import homeQRcode from "../../assets/qrCode.png";
import Instruction from "../ins/instruction";
import Terms from "../ins/terms";
import { json } from "express";
const { Option } = Select;
export const Tokuten: React.FunctionComponent = () => {
  let { t, i18n } = useTranslation()
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const [step, setStep] = useState(0);

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    }}>
      <div
        className={cssStyle.mainBackground}
        onMouseMove={
          (e) => {
            setX(e.clientX);
            setY(e.clientY);
          }
        }
        style={{
          backgroundImage: `url(${homeBackground})`,
          left: `calc(${x}px /40  - 100vw / 40)`,
          top: `calc(${y}px /40  - 100vh / 40)`,
        }}
      >
      </div>
      <div style={
        {
          width: "100vw",
          bottom: "0",
          position: "absolute"
        }
      }>

        <div style={{ margin: "0 auto", width: "fit-content" }}><a href="https://beian.miit.gov.cn/">京ICP备2021029154号-1</a></div>
      </div>

      <Row justify="center" align="middle" style={
        {
          width: "100vw",
          height: "100vh",
          position: "absolute"
        }
      }>
        <Col>
          <Card
            title={
              <>
                <Row>
                  <Col>
                    {t("tokutenTitle")}
                  </Col>
                  <Col style={{ paddingLeft: "15px" }}>
                    <Button icon={<TranslationOutlined />}
                      type="text"
                      onClick={() => {
                        i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en')
                      }}
                    >
                      {t("language")}
                    </Button>
                  </Col>
                </Row>
              </>
            }
            style={{
              background: "rgba(255,255,255,.85)",
              maxWidth: "95vw"
            }}
            bodyStyle={{
              maxHeight: "80vh",
              overflow: "auto"
            }}
            bordered={false}
          >
            {step === 0 && <Form
              autoComplete="off"
              name="basic"
              onFinish={(data) => {
                if (data.keyword.toLowerCase() === "JG2024".toLowerCase()) {
                  // window.location.href = "https://cloud.tsinghua.edu.cn/d/8b6954a0651b4310b04d/";
                  setStep(1) 
                }
                else {
                  message.warn(t("TokutenError"))
                }

              }}
            >
              <Form.Item
                label={t('keyword')}

                name='keyword'
              >
                <Input />
              </Form.Item>


              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('next')}
                </Button>
              </Form.Item>
            </Form>
            }
             {step === 1 && <Form
              autoComplete="off"
              name="basic"
            >

              <Form.Item>
              <Input.Group compact>
                <Input style={{ width: 'calc(100% - 120px)' }} value={t('FullVideo1')} readOnly/>
                  <Button type="primary"
                    style={{ width: '120px' }}
                    onClick={
                      () => {
                        window.open("https://cloud.tsinghua.edu.cn/d/8b6954a0651b4310b04d/files/?p=%2F%E9%AB%98%E7%94%BB%E8%B4%A8%E7%89%88%2F2024%20PURPLE%20BAKO%20V3.mp4&dl=1");
                      }
                    }
                  >{t("download")}</Button>
              </Input.Group>
              </Form.Item>
              <Form.Item>
              <Input.Group compact>
                <Input style={{ width: 'calc(100% - 120px)' }} value={t('FullVideo2')} readOnly/>
                  <Button type="primary"
                    style={{ width: '120px' }}
                    onClick={
                      () => {
                        window.open("https://cloud.tsinghua.edu.cn/d/8b6954a0651b4310b04d/files/?p=%2F%E6%B5%81%E7%95%85%E7%89%88%EF%BC%88%E5%A4%A7%E5%98%98%EF%BC%89%2F2024%20PURPLE%20BAKO%20V3%20(%E6%B5%81%E7%95%85).mp4&dl=1");
                      }
                    }
                  >{t("download")}</Button>
              </Input.Group>
              </Form.Item>
              <Form.Item>
                  <Button type="primary"
                    onClick={
                      () => {
                        message.info(t("ReadmeMsg"))
                      }
                    }
                  >{t("Readme")}</Button>
              </Form.Item>
            </Form>
            }
          
          </Card>

        </Col>
      </Row>

    </div>
  )
}
export default Tokuten;