/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  ConfigProvider, Card, Typography, Row, Col,Modal 
} from 'antd';
import React, { useEffect, useState, useCallback ,useRef} from "react";
import homeBackground from "../../assets/2024Gala/back.png";
import cssStyle from "./dice.module.css";
import TaoziFront from "../../assets/TaoziFront.png";
import QingfenFront from "../../assets/QingfenFront.png";
import ZijingFront from "../../assets/ZijingFront.png";
import TaoziBack from "../../assets/2024Gala/TaoziBack.png";
import QingfenBack from "../../assets/2024Gala/QingfenBack.png";
import ZijingBack from "../../assets/2024Gala/ZijingBack.png";
import FT0 from "../../assets/ft/ft0.png";
import FT1 from "../../assets/ft/ft1.png";
import FT2 from "../../assets/ft/ft2.png";
import FT3 from "../../assets/ft/ft3.png";
import FT4 from "../../assets/ft/ft4.png";
import FT5 from "../../assets/ft/ft5.png";
import FT6 from "../../assets/ft/ft6.png";
import FT7 from "../../assets/ft/ft7.png";
import FT8 from "../../assets/ft/ft8.png";
import FT9 from "../../assets/ft/ft9.png";

import axios from "axios"
import { boolean } from 'yargs';
const FT = [
  FT0,
  FT1,
  FT2,
  FT3,
  FT4,
  FT5,
  FT6,
  FT7,
  FT8,
  FT9,
]
const { Title, Text, Link } = Typography;
enum CardType {
  Taozi = "Taozi",
  Qingfen = "Qingfen",
  Zijing = "Zijing",
}

interface DiceCard {
  id: number;
  type: CardType;
  state: "Entry" | "Stay" | "Exit";
  front: boolean;
}

const customizeRenderEmpty = () => (
  <div>
  </div>
);

export const FlipCard: React.FC<{ card: DiceCard; }> = ({ card }) => {
  const [showFront, setShowFront] = useState(!card.front);

  useEffect(() => {
    setShowFront(!card.front);
  }, [card]);

  return (
    <div 
      className={cssStyle[`card`] + " " + cssStyle[`card${card.state}`]}
    >
      <div
        className={cssStyle[`card-${showFront ? "front" : "back"}`]}
        style={{
          backgroundImage: `url(${(card.type == CardType.Taozi) ? TaoziBack :
            (card.type == CardType.Qingfen) ? QingfenBack :
              (card.type == CardType.Zijing) ? ZijingBack : ""
            })`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%"
        }}

      >

      </div>
      <div
        className={cssStyle[`card-${showFront ? "back" : "front"}`]}

        style={{
          width: "18vw",
          height: "24vw",
          backgroundImage: `url(${(card.type == CardType.Taozi) ? TaoziFront :
            (card.type == CardType.Qingfen) ? QingfenFront :
              (card.type == CardType.Zijing) ? ZijingFront : ""
            })`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%"
        }}
      >
        <Row
          justify="space-between"
          style={{
            height: "30%",
            width: "60%",
            transform: "translateY(-80%)"
          }}>
          <Col span="6">
            <div style={{
              backgroundImage: `url(${FT[Math.floor(card.id / 100)]})`,
            }}
              className={cssStyle.numbers}

            />
          </Col>
          <Col span="6">
            <div style={{
              backgroundImage: `url(${FT[Math.floor(card.id / 10) % 10]})`,
            }}

              className={cssStyle.numbers}
            />
          </Col>
          <Col span="6">
            <div style={{
              backgroundImage: `url(${FT[card.id % 10]})`
            }}
              className={cssStyle.numbers} />
          </Col>
        </Row>

      </div>
    </div>
  );
}

export const DispPage: React.FunctionComponent<{id:string}> = ({id}) => {
  const [list, setList] = useState<DiceCard[]>([]);
  const [GlobalDeckData, setGlobalDeckData] = useState<DiceCard[]>([]);
  const [GlobalTData, setGlobalTData] = useState<number>(0);
  const GlobalDeckRef = useRef<DiceCard[]>([]);
  const GlobalTRef = useRef<number>(0);

  const showModal = () => {
    Modal.info({
      title: '帮助',
      content: (
        <div>
         <p>键盘操作，页面 <Text code>/dice?id=$id</Text>为抽奖界面。
            共计清芬，紫荆，桃子共<Text code>$id</Text>张。键盘操作。<br />
            
            <Text code>1-5</Text>：  <Text mark>之前抽过地不放回地背面朝上</Text>抽<Text code>1-5</Text>张。<br />
            <Text code>6-0</Text>：  之前抽过地不放回地重新抽<Text mark>第</Text><Text code>1-5</Text>张。<br />
            <Text code>q</Text>：  <Text mark>弃掉</Text>所有牌。<br />
            <Text code>e</Text>：  所有牌<Text mark>翻成正面</Text>。<br />
            <Text code>f</Text>：  <Text mark>翻转</Text>所有牌。<br />
            <Text code>r</Text>：  弃掉所有牌,并重新<Text mark>洗牌</Text>。<br />
            <Text code>h</Text>：  打开帮助界面。<br />
            <Text code>鼠标左键</Text>：  打开或者关闭所有牌。
            </p>
        </div>
      ),
      onOk() {},
    });
  };


  const getnums = () => Array.from<number,number>({ length: parseInt(id || "0") || 0 }, (_, i) => i + 1);
  useEffect(
    () => {
      GlobalDeckRef.current = GlobalDeckData;
    }, [GlobalDeckData]
  );

  useEffect(
    () => {
      GlobalTRef.current = GlobalTData;
    }, [GlobalTData]
  );

  function genOne(): DiceCard {
    
    if (GlobalTRef.current < GlobalDeckRef.current.length) {
      let t = GlobalDeckRef.current[GlobalTRef.current];
      setGlobalTData(GlobalTData=> (GlobalTData + 1));
      return t;
    } else {
      return { type: CardType.Taozi, id: 0, state: "Entry", front: false,}
    }
  }

  function shuffleCard() {

    const dice = getnums().map<DiceCard>(num => ({
      id: Math.ceil(num/3),
      type:
        (num % 3) == 0 ? CardType.Taozi :
          (num % 3) == 1 ? CardType.Qingfen :
            CardType.Zijing,
      state: "Entry",
      front: false,
    }));
      
      console.log(dice)

      setGlobalDeckData((_) =>
        dice
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
      );
  }

  useEffect(() => {
    shuffleCard();
    showModal();
  }, []);

  async function genNew (i: number) {
    let len = (i > 5) ? 5 :
      (i < 1) ? 1 : i;
    const todo: DiceCard[] = [];
    let addn = 0;
    let t = GlobalTRef.current;
    for (i = 0; i < len; i++) {
      if (t + i < GlobalDeckRef.current.length) {
        todo.push(GlobalDeckRef.current[t + i]);
        addn++;
      } else {
        todo.push({ type: CardType.Taozi, id: 0, state: "Entry" ,front:false});
      }
    }
    setGlobalTData(GlobalTData=> (GlobalTData + addn));
    await setList([]);
    await sleep(50);
    await setList(todo);
    await sleep(50);
  }

  const onKeyDown = useCallback(async (e: KeyboardEvent) => {
    switch (e.key) {
      case "1":
        await allExit();
        await genNew(1);
        await allEntry();
        break;
      case "2":
        await allExit();
        await genNew(2);
        await allEntry();
        break;
      case "3":
        await allExit();
        await genNew(3);
        await allEntry();
        break;
      case "4":
        await allExit();
        await genNew(4);
        await allEntry();
        break;
      case "5":
        await allExit();
        await genNew(5);
        await allEntry();
        break;
      case "6":
        await exitCard(0);
        await changeCard(0, genOne());
        await entryCard(0);
        break;
      case "7":
        await exitCard(1);
        await changeCard(1, genOne());
        await entryCard(1);
        break;
      case "8":
        await exitCard(2);
        await changeCard(2, genOne());
        await entryCard(2);
        break;
      case "9":
        await exitCard(3);
        await changeCard(3, genOne());
        await entryCard(3);
        break;
      case "0":
        await exitCard(4);
        await changeCard(4, genOne());
        await entryCard(4);
        break;
      case "q":
        await allExit();
        break;
      case "e":
        await allFlipCard(true)
        break;
      case "f":
        await allFlipCard()
        break;
      // case "x":
      //   await allExit();
      //   setList([]);
      //   break;
      case "r":
        await allExit();
        shuffleCard();
        setGlobalTData(0);
        break;
      case "h":
        showModal();
        break;
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const sleep = (t: number) => new Promise((res) => setTimeout(res, t));

  async function exitCard(i: number) {
    setList(olist => olist.map(
      (x, j) => {
        if (i != j) return { ...x };
        else return { ...x, state: "Exit" }
      }
    )
    )
    await sleep(200);
  }

  async function changeCard (i: number, newC: DiceCard) {
    setList(olist => olist.map(
      (x, j) => {
        if (i != j) return { ...x };
        else return { ...newC }
      }
    )
    )
  }

  async function allExit () {
    for (let i = 0; i < 5; i++)
      await exitCard(i)
  }


  async function entryCard (i: number){
    setList(olist => olist.map(
      (x, j) => {
        if (i != j) return { ...x };
        else return { ...x, state: "Stay" }
      }
    )
    )
    await sleep(200);
  }

  async function allEntry (){
    for (let i = 0; i < 5; i++)
      await entryCard(i)

  }

  async function allFlipCard (front?: boolean) {
    for (let i = 0; i < 5; i++)
      await flipCard(i,front)
 
  }

  async function flipCard (i: number, front?: boolean) {
    setList(olist => olist.map(
      (x, j) => {
        if (i != j) return { ...x };
        else return { ...x, front:front=== undefined?(!x.front):front }
      }
    )
    )
  }


  useEffect(() => {
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown); // 销毁
    };
  }, [list]);

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    }}
    >
      <div
        className={cssStyle.mainBackground}

        style={{
          backgroundImage: `url(${homeBackground})`,
        }}
      >
      </div>
      <Row justify="space-around" align="middle" style={{ height: "100vh", width: "100vw" }}>

        {
          list.map((item, i) => (
            <Col key={`flip-${i}`}>
              <div 
                onClick={() => {
                  flipCard(i);
                }}>
              <FlipCard
                card={item}
                
                />
            </div>
            </Col>
          ))
        }
      </Row>
      <div>
      <p>
          {JSON.stringify(GlobalDeckData)}
        </p>
      </div>

    </div>
  )
}
export default DispPage;